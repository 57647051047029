
import {
  defineComponent,
  ref,
  unref,
  toRefs,
  watch
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  ElMessage,
  ElForm
} from 'element-plus';

import { Rules } from 'async-validator';
import useFormErrors from '@/utils/validate';
import { useUpdateOrder, useOrder } from '@/composables/api';
import { PartialOrder } from '@/interfaces/Order';

/* eslint-disable @typescript-eslint/camelcase */
const DEFAULT_FORM_VALUES: PartialOrder = {
  transaction_fee: null,
  settlement_fee: null,
  reserve_fee: null
};

const rules: Rules = {
  // name: [
  //   {
  //     required: true
  //   }
  // ],
  // sort: [
  //   {
  //     required: true
  //   }
  // ]
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const id = useRoute().params.id as string;
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdateOrder();
    const { formErrors, bindFormItemError } = useFormErrors();

    const { data } = useOrder({ id }, { enabled: isEdit.value });

    watch(data, ({ data }) => {
      formValues.value = data;
    });

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (valid) {
          if (isEdit.value) {
            update({ id, data: formValues.value }, {
              onSuccess() {
                ElMessage.success({
                  message: 'success!',
                  type: 'success'
                });

                router.go(-1);
              },
              onError(error: any) {
                ElMessage.error({
                  message: error.response?.data.message,
                  type: 'error'
                });
                formErrors.value = error.response?.data.errors;
              }
            });
          }
        }
      });
    };

    return {
      formValues,
      formRef,
      rules,
      isUpdatedLoading,
      submitForm,
      formErrors,
      bindFormItemError
    };
  }
});
